:root {
  --clr-white: #fff;
  --clr-black: #1f1f1f;
  --clr-brand: #0abab5;
  --clr-body-bg: #f6f6f6;
  --clr-text: #1c1c1e;
  --clr-grey-text: #70707b;

  --clr-icon: var(--clr-brand);

  --header-height: 77px;
  --marquee-item-count: 12;
  --marquee-item-width: 7rem;
  --marquee-item-gap: 2rem;
}

[aria-hidden="true"] {
  display: none !important;
}

body {
  background: var(--clr-body-bg);
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 140%;
  color: var(--clr-text);
}

body.no-scroll {
  overflow: hidden;
}

h1,
.section-title {
  font-size: 2rem;
  font-weight: 700;
  line-height: 130%;
}

.container {
  max-width: 1216px;
  margin: 0 auto;
}

.section-title {
  margin-bottom: 1rem;
}

.landing-card {
  padding: 2rem 1rem;
  background-color: var(--clr-white);
  border-radius: 1.5rem;
}

.landing-card__icon {
  width: 2.5rem;
  height: 2.5rem;
  margin-bottom: 0.75rem;
}

.landing-card__title {
  margin-bottom: 0.375rem;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 140%;
}

.landing-card__text {
  font-size: 0.875rem;
  line-height: 150%;
}

a {
  text-decoration: none;
  color: inherit;
}

label {
  display: block;
  position: relative
}

label span {
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
  cursor: text;
  font-weight: 600;
  color: var(--clr-grey-text);
  transition: top 0.1s linear, transform 0.1s linear;
}

input,
textarea {
  padding: 1.5rem 1rem 0.5rem;
  background-color: var(--clr-body-bg);
  border-radius: 0.75rem;
  font-weight: 600;
}

input:focus + span,
input.has-value + span,
textarea:focus + span,
textarea.has-value + span {
  top: 0.5rem;
  transform: translateY(0);
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 150%;
}

label textarea + span {
  top: 1.75rem;
}

textarea {
  min-height: 5rem;
}

.button, .icon-button {
  width: max-content;
  background-color: var(--clr-brand);
  border-radius: 1rem;
}

.button:hover,
.button:focus-visible,
.icon-button:hover,
.icon-button:focus-visible {
  background-color: #05e4de;
}

.button:active,
.icon-button:active {
  background-color: #05a09c;
}

.button {
  padding: 0.75rem 1.5rem;
  font-weight: 600;
  color: var(--clr-white);
}

.icon-button {
  --clr-icon: var(--clr-white);

  padding: 0.75rem;
}

.icon-button svg {
  width: 1.25rem;
  height: 1.25rem;
}

.icon-button--clear {
  --clr-icon: var(--clr-black);
  background-color: transparent;
}

.icon-button--clear:hover,
.icon-button--clear:focus-visible {
  background-color: #d9d9d9;
}

.icon-button--clear:active {
  background-color: #cfcfd5;
}

header,
main,
footer {
  padding: 0 1rem;
}

header {
  position: sticky;
  top: 0;
  border-bottom: 1px solid #e9e9e9;
  background: rgba(246, 246, 246, 0.70);
  backdrop-filter: blur(6px);
  margin-bottom: 1rem;
  z-index: 10;
}

nav {
  display: flex;
  align-items: center;
  padding: 1rem 0;
}

section {
  margin-bottom: 4rem;
}

.menu {
  position: fixed;
  top: var(--header-height);
  left: 0;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
  width: 100%;
  height: calc(100vh - var(--header-height));
  overflow: auto;
  background: rgba(246, 246, 246, 0.70);
  backdrop-filter: blur(6px);
  z-index: 15;
}

.menu--open {
  display: flex;
}

.menu a {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 130%;
}

.logo {
  margin-right: auto;
}

.nav-controls {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.hamburger .close,
.hamburger--open .open {
  display: none;
}

.hamburger--open .close {
  display: block;
}

.hero-wrapper {
  padding: 1.25rem 1rem 3rem;
  background-color: var(--clr-white);
  border-radius: 1.5rem;
}

.hero-info-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
  margin-bottom: 1.75rem;
}

.hero-visual img {
  width: 100%;
}

.hero-title {
  margin-bottom: 0.5rem;
}

.hero-items {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}

.hero-items .landing-card {
  padding: 0;
}

.benefits-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.who-uses {
  margin-top: 4rem;
}

.banks-title {
  margin-bottom: 2.8rem;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 130%;
}

.banks-list {
  margin: 0 -1rem;
  padding: 0 1rem;
  overflow: hidden;
}

.marquee {
  display: flex;
  align-items: center;
  gap: var(--marquee-item-gap);
  width: calc((var(--marquee-item-width) * var(--marquee-item-count)) + (var(--marquee-item-gap) * (var(--marquee-item-count) - 1)));
  max-height: 2.5rem;
  animation: marquee 10s linear infinite;
}

.marquee__item {
  object-fit: contain;
  max-width: var(--marquee-item-width);
  max-height: 100%;
}

.stats-list {
  --stack-cards-gap: 1rem;

  display: flex;
  flex-direction: column;
  gap: var(--stack-cards-gap);
}

.stats-list__item {
  padding: 2rem 1rem;
  background-color: var(--clr-white);
  border-radius: 1.5rem;
  text-align: center;
}

.stats-list__item-title {
  margin-bottom: 1.5rem;
  font-size: 1rem;
  line-height: 130%;
  font-weight: 600;
}

.stats-list__item-title span {
  color: var(--clr-brand);
}

.stats-colors {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-bottom: 2.5rem;
  color: var(--clr-grey-text);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 150%;
}

.stats-colors__item {
  position: relative;
  display: flex;
  align-items: center;
}

.stats-colors__item::before {
  content: '';
  display: block;
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  border-radius: 0.1rem;
}

.stats-colors__item--red::before {
  background-color: #c9501c;
}

.stats-colors__item--grey::before {
  background-color: #70707b;
}

.specs-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.stats-colors__item--purple::before {
  background-color: #8054ff;
}

.stats-list__item {
  max-height: 80vh;
}

.stats-list__item .chart img {
  margin: 0 auto;
}

.stats-list__item .percent {
  padding: 2rem 0 1.5rem;
  color: var(--clr-brand);
  font-size: 5.75rem;
  font-weight: 700;
  line-height: 120%;
}

.stats-list__item .info {
  margin-top: 5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 140%;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 4rem
}

.steps-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.steps-list__item {
  --clr-icon: var(--clr-black);

  position: relative;
  padding: 5rem 1rem;
  background-color: var(--clr-white);
  border-radius: 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 130%;
  text-align: center;
}

.step-count {
  position: absolute;
  top: 1rem;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 1rem;
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 130%;
  color: var(--clr-brand);
}

.step-info .or-text {
  margin: 0.25rem 0;
  color: var(--clr-grey-text);
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.form-wrapper--modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(246, 246, 246, 0.70);
  backdrop-filter: blur(6px);
  padding: 5rem 1rem;
  z-index: 20;
}

.form-wrapper--modal .close-form {
  display: block;
}

.form-wrapper--modal.form-wrapper--modal-success .send-status--success,
.form-wrapper--modal.form-wrapper--modal-error .send-status--error {
  display: flex;
}

.form-wrapper--modal.form-wrapper--modal-success .send-status--error,
.form-wrapper--modal.form-wrapper--modal-error .send-status--success,
.form-wrapper--modal.form-wrapper--modal-success #contact-form,
.form-wrapper--modal.form-wrapper--modal-error #contact-form {
  display: none;
}

.modal-body {
  position: relative;
  padding: 1.5rem 1rem;
  background-color: var(--clr-white);
  border-radius: 1.5rem;
  overflow: auto;
}

.form-wrapper--modal .modal-body {
  max-height: 100%;
}

.close-form {
  --clr-icon: var(--clr-black);

  display: none;
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  width: max-content;
}

.form-title {
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: 130%;
}

.form-text {
  margin-bottom: 1.25rem;
}

.form-wrapper .button {
  width: 100%;
}

.input-list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-bottom: 1rem;
}

.send-status {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  text-align: center;
}

.status-icon {
  margin-bottom: 2.25rem;
}

.status-title {
  margin-bottom: 0.5rem;
}

.status-text {
  margin-bottom: 1.5rem;
}

footer {
  padding-bottom: 2rem;
}

.contacts-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.contacts-list .landing-card__title {
  margin: 0;
}

.contacts-list .landing-card__text {
  margin-bottom: 0.35rem;
}

@media only screen and (min-width: 750px) {
  .section-title {
    margin-bottom: 2rem;
  }

  .landing-card {
    padding: 3rem 2rem;
  }

  .landing-card__icon {
    margin-bottom: 1.5rem;
  }

  .hero-info-wrapper {
    flex-direction: row-reverse;
    gap: 2rem;
  }

  .hero-info {
    flex: 40%;
  }

  .hero-visual {
    flex: 60%
  }

  .hero-items {
    padding-top: 1.5rem;
    border-top: 1px solid #e9e9e9;
    grid-template-columns: repeat(3, 1fr);
  }

  .benefits-list,
  .specs-list {
    grid-template-columns: 1fr 1fr;
  }

  .benefits--bank .landing-card:first-child {
    grid-column: span 2;
  }

  .who-uses {
    display: flex;
    gap: 3rem;
    padding-bottom: 3rem;
  }

  .banks-title {
    margin-bottom: 0;
    font-size: 1.75rem;
  }

  .banks-list {
    position: relative;
  }

  .banks-list:before,
  .banks-list:after {
    content: '';
    position: absolute;
    top: 0;
    width: 7rem;
    height: 100%;
    z-index: 1;
  }

  .banks-list:before {
    left: 0;
    background: linear-gradient(90deg, var(--clr-body-bg) 0%, rgba(246, 246, 246, 0.80) 40%, rgba(246, 246, 246, 0.00) 100%);
  }

  .banks-list:after {
    right: 0;
    background: linear-gradient(-90deg, var(--clr-body-bg) 0%, rgba(246, 246, 246, 0.80) 40%, rgba(246, 246, 246, 0.00) 100%);
  }

  #stats .section-title {
    text-align: center;
  }

  .stats-list__item {
    position: sticky;
    top: var(--header-height);
    padding: 3.5rem;
    transform-origin: center top;
    box-shadow: 0 0 48px 0 rgba(57, 63, 96, 0.12);
  }

  .stats-list__item > * {
    max-width: 85%;
    margin-left: auto;
    margin-right: auto;
  }

  .stats-list__item-title {
    margin-bottom: 2.5em;
    font-size: 1.5rem;
  }

  .stats-colors {
    justify-content: center;
  }

  .form-container {
    flex-direction: row;
    gap: 1rem;
  }

  .steps-list {
    flex: 50%;
  }

  .form-wrapper {
    flex: 50%;
  }

  .modal-body {
    position: sticky;
    top: 2rem;
  }

  .form-wrapper--modal .modal-body {
    max-width: 30rem;
    max-height: 80vh;
  }
}


@media only screen and (min-width: 990px) {
  section {
    margin-bottom: 10rem;
  }

  h1,
  .section-title {
    font-size: 3rem;
    line-height: 120%;
  }

  .hero-wrapper {
    padding: 4rem;
  }

  .hero-info-wrapper {
    gap: 4rem;
  }

  .hero-info {
    flex: 50%;
  }

  .hero-visual {
    flex: 50%
  }

  .stats-list__item > * {
    max-width: 60%;
  }

  .steps-list__item {
    font-size: 1.5rem;
  }

  .contacts-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 1099px) {
  .header-open {
    background: var(--clr-body-bg);
    backdrop-filter: none;
  }
}

@media only screen and (min-width: 1100px) {
  :root {
    --header-height: 79px;
  }

  .menu {
    display: flex;
    flex-direction: row;
    position: static;
    width: auto;
    height: auto;
    gap: 1.5rem;
    backdrop-filter: none;
    background-color: transparent;
    justify-content: flex-start;
  }

  .menu a {
    font-weight: 400;
    font-size: 1rem;
  }

  .nav-controls {
    display: none;
  }

  .benefits-list,
  .specs-list {
    gap: 2rem;
  }

  .benefits--bank .benefits-list,
  .specs-list {
    grid-template-columns: repeat(3, 1fr);
  }

  .stats-list {
    --stack-cards-gap: 3rem;
  }

  .stats-list__item .percent {
    font-size: 10rem;
  }

  .form-container {
    gap: 2rem;
  }

  .steps-list {
    flex: 60%;
  }

  .form-wrapper {
    flex: 40%;
  }

  .modal-body {
    padding: 3rem;
  }

  .form-title {
    margin-bottom: 0.75rem;
    font-size: 2rem;
  }

  .form-text {
    margin-bottom: 1.5rem;
  }

  footer {
    padding-bottom: 10rem;
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(((var(--marquee-item-width) * (var(--marquee-item-count) / 2)) + (var(--marquee-item-gap) * var(--marquee-item-count) / 2)) * -1));
  }
}
