:root {
  --clr-white: #fff;
  --clr-black: #1f1f1f;
  --clr-brand: #0abab5;
  --clr-body-bg: #f6f6f6;
  --clr-text: #1c1c1e;
  --clr-grey-text: #70707b;
  --clr-icon: var(--clr-brand);
  --header-height: 77px;
  --marquee-item-count: 12;
  --marquee-item-width: 7rem;
  --marquee-item-gap: 2rem;
}

[aria-hidden="true"] {
  display: none !important;
}

body {
  background: var(--clr-body-bg);
  color: var(--clr-text);
  font-family: Poppins, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 140%;
}

body.no-scroll {
  overflow: hidden;
}

h1, .section-title {
  font-size: 2rem;
  font-weight: 700;
  line-height: 130%;
}

.container {
  max-width: 1216px;
  margin: 0 auto;
}

.section-title {
  margin-bottom: 1rem;
}

.landing-card {
  background-color: var(--clr-white);
  border-radius: 1.5rem;
  padding: 2rem 1rem;
}

.landing-card__icon {
  width: 2.5rem;
  height: 2.5rem;
  margin-bottom: .75rem;
}

.landing-card__title {
  margin-bottom: .375rem;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 140%;
}

.landing-card__text {
  font-size: .875rem;
  line-height: 150%;
}

a {
  color: inherit;
  text-decoration: none;
}

label {
  display: block;
  position: relative;
}

label span {
  cursor: text;
  color: var(--clr-grey-text);
  font-weight: 600;
  transition: top .1s linear, transform .1s linear;
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
}

input, textarea {
  background-color: var(--clr-body-bg);
  border-radius: .75rem;
  padding: 1.5rem 1rem .5rem;
  font-weight: 600;
}

input:focus + span, input.has-value + span, textarea:focus + span, textarea.has-value + span {
  font-size: .75rem;
  font-weight: 400;
  line-height: 150%;
  top: .5rem;
  transform: translateY(0);
}

label textarea + span {
  top: 1.75rem;
}

textarea {
  min-height: 5rem;
}

.button, .icon-button {
  background-color: var(--clr-brand);
  border-radius: 1rem;
  width: max-content;
}

.button:hover, .button:focus-visible, .icon-button:hover, .icon-button:focus-visible {
  background-color: #05e4de;
}

.button:active, .icon-button:active {
  background-color: #05a09c;
}

.button {
  color: var(--clr-white);
  padding: .75rem 1.5rem;
  font-weight: 600;
}

.icon-button {
  --clr-icon: var(--clr-white);
  padding: .75rem;
}

.icon-button svg {
  width: 1.25rem;
  height: 1.25rem;
}

.icon-button--clear {
  --clr-icon: var(--clr-black);
  background-color: #0000;
}

.icon-button--clear:hover, .icon-button--clear:focus-visible {
  background-color: #d9d9d9;
}

.icon-button--clear:active {
  background-color: #cfcfd5;
}

header, main, footer {
  padding: 0 1rem;
}

header {
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  z-index: 10;
  background: #f6f6f6b3;
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 1rem;
  position: sticky;
  top: 0;
}

nav {
  align-items: center;
  padding: 1rem 0;
  display: flex;
}

section {
  margin-bottom: 4rem;
}

.menu {
  top: var(--header-height);
  height: calc(100vh - var(--header-height));
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  z-index: 15;
  background: #f6f6f6b3;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
  width: 100%;
  display: none;
  position: fixed;
  left: 0;
  overflow: auto;
}

.menu--open {
  display: flex;
}

.menu a {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 130%;
}

.logo {
  margin-right: auto;
}

.nav-controls {
  align-items: center;
  gap: .5rem;
  display: flex;
}

.hamburger .close, .hamburger--open .open {
  display: none;
}

.hamburger--open .close {
  display: block;
}

.hero-wrapper {
  background-color: var(--clr-white);
  border-radius: 1.5rem;
  padding: 1.25rem 1rem 3rem;
}

.hero-info-wrapper {
  flex-direction: column;
  gap: 1.75rem;
  margin-bottom: 1.75rem;
  display: flex;
}

.hero-visual img {
  width: 100%;
}

.hero-title {
  margin-bottom: .5rem;
}

.hero-items {
  grid-template-columns: 1fr;
  gap: 1.5rem;
  display: grid;
}

.hero-items .landing-card {
  padding: 0;
}

.benefits-list {
  grid-template-columns: 1fr;
  gap: 1rem;
  display: grid;
}

.who-uses {
  margin-top: 4rem;
}

.banks-title {
  margin-bottom: 2.8rem;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 130%;
}

.banks-list {
  margin: 0 -1rem;
  padding: 0 1rem;
  overflow: hidden;
}

.marquee {
  align-items: center;
  gap: var(--marquee-item-gap);
  width: calc((var(--marquee-item-width) * var(--marquee-item-count))  + (var(--marquee-item-gap) * (var(--marquee-item-count)  - 1)));
  max-height: 2.5rem;
  animation: 10s linear infinite marquee;
  display: flex;
}

.marquee__item {
  object-fit: contain;
  max-width: var(--marquee-item-width);
  max-height: 100%;
}

.stats-list {
  --stack-cards-gap: 1rem;
  gap: var(--stack-cards-gap);
  flex-direction: column;
  display: flex;
}

.stats-list__item {
  background-color: var(--clr-white);
  text-align: center;
  border-radius: 1.5rem;
  padding: 2rem 1rem;
}

.stats-list__item-title {
  margin-bottom: 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 130%;
}

.stats-list__item-title span {
  color: var(--clr-brand);
}

.stats-colors {
  color: var(--clr-grey-text);
  flex-wrap: wrap;
  gap: .75rem;
  margin-bottom: 2.5rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 150%;
  display: flex;
}

.stats-colors__item {
  align-items: center;
  display: flex;
  position: relative;
}

.stats-colors__item:before {
  content: "";
  border-radius: .1rem;
  width: 1rem;
  height: 1rem;
  margin-right: .5rem;
  display: block;
}

.stats-colors__item--red:before {
  background-color: #c9501c;
}

.stats-colors__item--grey:before {
  background-color: #70707b;
}

.specs-list {
  grid-template-columns: 1fr;
  gap: 1rem;
  display: grid;
}

.stats-colors__item--purple:before {
  background-color: #8054ff;
}

.stats-list__item {
  max-height: 80vh;
}

.stats-list__item .chart img {
  margin: 0 auto;
}

.stats-list__item .percent {
  color: var(--clr-brand);
  padding: 2rem 0 1.5rem;
  font-size: 5.75rem;
  font-weight: 700;
  line-height: 120%;
}

.stats-list__item .info {
  margin-top: 5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 140%;
}

.form-container {
  flex-direction: column;
  gap: 4rem;
  display: flex;
}

.steps-list {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.steps-list__item {
  --clr-icon: var(--clr-black);
  background-color: var(--clr-white);
  text-align: center;
  border-radius: 1.5rem;
  padding: 5rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 130%;
  position: relative;
}

.step-count {
  color: var(--clr-brand);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 1rem;
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 130%;
  display: flex;
  position: absolute;
  top: 1rem;
  left: 0;
}

.step-info .or-text {
  color: var(--clr-grey-text);
  margin: .25rem 0;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.form-wrapper--modal {
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  z-index: 20;
  background: #f6f6f6b3;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 5rem 1rem;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.form-wrapper--modal .close-form {
  display: block;
}

.form-wrapper--modal.form-wrapper--modal-success .send-status--success, .form-wrapper--modal.form-wrapper--modal-error .send-status--error {
  display: flex;
}

.form-wrapper--modal.form-wrapper--modal-success .send-status--error, .form-wrapper--modal.form-wrapper--modal-error .send-status--success, .form-wrapper--modal.form-wrapper--modal-success #contact-form, .form-wrapper--modal.form-wrapper--modal-error #contact-form {
  display: none;
}

.modal-body {
  background-color: var(--clr-white);
  border-radius: 1.5rem;
  padding: 1.5rem 1rem;
  position: relative;
  overflow: auto;
}

.form-wrapper--modal .modal-body {
  max-height: 100%;
}

.close-form {
  --clr-icon: var(--clr-black);
  width: max-content;
  display: none;
  position: absolute;
  top: .75rem;
  right: .75rem;
}

.form-title {
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: 130%;
}

.form-text {
  margin-bottom: 1.25rem;
}

.form-wrapper .button {
  width: 100%;
}

.input-list {
  flex-direction: column;
  gap: .75rem;
  margin-bottom: 1rem;
  display: flex;
}

.send-status {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  display: none;
}

.status-icon {
  margin-bottom: 2.25rem;
}

.status-title {
  margin-bottom: .5rem;
}

.status-text {
  margin-bottom: 1.5rem;
}

footer {
  padding-bottom: 2rem;
}

.contacts-list {
  grid-template-columns: 1fr;
  gap: 1rem;
  display: grid;
}

.contacts-list .landing-card__title {
  margin: 0;
}

.contacts-list .landing-card__text {
  margin-bottom: .35rem;
}

@media only screen and (width >= 750px) {
  .section-title {
    margin-bottom: 2rem;
  }

  .landing-card {
    padding: 3rem 2rem;
  }

  .landing-card__icon {
    margin-bottom: 1.5rem;
  }

  .hero-info-wrapper {
    flex-direction: row-reverse;
    gap: 2rem;
  }

  .hero-info {
    flex: 40%;
  }

  .hero-visual {
    flex: 60%;
  }

  .hero-items {
    border-top: 1px solid #e9e9e9;
    grid-template-columns: repeat(3, 1fr);
    padding-top: 1.5rem;
  }

  .benefits-list, .specs-list {
    grid-template-columns: 1fr 1fr;
  }

  .benefits--bank .landing-card:first-child {
    grid-column: span 2;
  }

  .who-uses {
    gap: 3rem;
    padding-bottom: 3rem;
    display: flex;
  }

  .banks-title {
    margin-bottom: 0;
    font-size: 1.75rem;
  }

  .banks-list {
    position: relative;
  }

  .banks-list:before, .banks-list:after {
    content: "";
    z-index: 1;
    width: 7rem;
    height: 100%;
    position: absolute;
    top: 0;
  }

  .banks-list:before {
    background: linear-gradient(90deg, var(--clr-body-bg) 0%, #f6f6f6cc 40%, #f6f6f600 100%);
    left: 0;
  }

  .banks-list:after {
    background: linear-gradient(-90deg, var(--clr-body-bg) 0%, #f6f6f6cc 40%, #f6f6f600 100%);
    right: 0;
  }

  #stats .section-title {
    text-align: center;
  }

  .stats-list__item {
    top: var(--header-height);
    transform-origin: top;
    padding: 3.5rem;
    position: sticky;
    box-shadow: 0 0 48px #393f601f;
  }

  .stats-list__item > * {
    max-width: 85%;
    margin-left: auto;
    margin-right: auto;
  }

  .stats-list__item-title {
    margin-bottom: 2.5em;
    font-size: 1.5rem;
  }

  .stats-colors {
    justify-content: center;
  }

  .form-container {
    flex-direction: row;
    gap: 1rem;
  }

  .steps-list, .form-wrapper {
    flex: 50%;
  }

  .modal-body {
    position: sticky;
    top: 2rem;
  }

  .form-wrapper--modal .modal-body {
    max-width: 30rem;
    max-height: 80vh;
  }
}

@media only screen and (width >= 990px) {
  section {
    margin-bottom: 10rem;
  }

  h1, .section-title {
    font-size: 3rem;
    line-height: 120%;
  }

  .hero-wrapper {
    padding: 4rem;
  }

  .hero-info-wrapper {
    gap: 4rem;
  }

  .hero-info, .hero-visual {
    flex: 50%;
  }

  .stats-list__item > * {
    max-width: 60%;
  }

  .steps-list__item {
    font-size: 1.5rem;
  }

  .contacts-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (width <= 1099px) {
  .header-open {
    background: var(--clr-body-bg);
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
  }
}

@media only screen and (width >= 1100px) {
  :root {
    --header-height: 79px;
  }

  .menu {
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
    background-color: #0000;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1.5rem;
    width: auto;
    height: auto;
    display: flex;
    position: static;
  }

  .menu a {
    font-size: 1rem;
    font-weight: 400;
  }

  .nav-controls {
    display: none;
  }

  .benefits-list, .specs-list {
    gap: 2rem;
  }

  .benefits--bank .benefits-list, .specs-list {
    grid-template-columns: repeat(3, 1fr);
  }

  .stats-list {
    --stack-cards-gap: 3rem;
  }

  .stats-list__item .percent {
    font-size: 10rem;
  }

  .form-container {
    gap: 2rem;
  }

  .steps-list {
    flex: 60%;
  }

  .form-wrapper {
    flex: 40%;
  }

  .modal-body {
    padding: 3rem;
  }

  .form-title {
    margin-bottom: .75rem;
    font-size: 2rem;
  }

  .form-text {
    margin-bottom: 1.5rem;
  }

  footer {
    padding-bottom: 10rem;
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(((var(--marquee-item-width) * (var(--marquee-item-count) / 2))  + (var(--marquee-item-gap) * var(--marquee-item-count) / 2)) * -1));
  }
}

/*# sourceMappingURL=index.296c99f2.css.map */
